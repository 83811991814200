export const DEFAULTS = {
  effect: 'fade',
  delaySequence: [
    'delay-0',
    'delay-100',
    'delay-200',
    'delay-300',
    'delay-400',
    'delay-500',
    'delay-600',
    'delay-700',
    'delay-800',
    'delay-900',
  ] as string[],
  intersectionObserverOptions: {rootMargin: '-50px', threshold: 0},
  stagger: true,
} as const;

export const ANIMATION_TYPE_PRESETS = {
  fade: {
    static: 'transition-opacity ease-base duration-700 opacity-0',
    animated: 'opacity-100',
  },
  rise: {
    static:
      'transition-opacity-transform ease-base duration-700 opacity-0 translate-y-5',
    animated: 'translate-y-0 opacity-100',
  },
  slide: {
    static:
      'transition-opacity-transform ease-base duration-700 opacity-0 translate-x-5',
    animated: 'translate-x-0 opacity-100',
  },
} as const;
