import Image, {type ImageProps} from '@/components/base/elements/Image/Image';
import {twMerge} from '@/stylesheets/twMerge';

export interface BGStaticImageProps {
  image: ImageProps;
  mobileImage?: ImageProps;
  containerClassName?: string;
  imageClassName?: string;
  mobileImageClassName?: string;
}

export default function BGStaticImage({
  image,
  mobileImage,
  containerClassName,
  imageClassName,
  mobileImageClassName,
}: BGStaticImageProps) {
  return (
    <div className={twMerge('absolute w-full h-full', containerClassName)}>
      <Image
        {...image}
        className={twMerge(
          'w-full h-full object-cover hidden md:block',
          imageClassName,
        )}
      />
      {mobileImage && (
        <Image
          {...mobileImage}
          className={twMerge(
            'w-full h-full object-cover block md:hidden',
            mobileImageClassName,
          )}
        />
      )}
    </div>
  );
}
