import {createContext, useContext} from 'react';

import type {AnimateInProviderProps, AnimateInProviderValue} from './types';

const AnimateInContext = createContext<AnimateInProviderValue>({
  animationClassName: '',
  delayClassNames: [],
});

export const AnimateInProvider = ({
  children,
  value,
}: AnimateInProviderProps) => (
  <AnimateInContext.Provider value={value}>
    {children}
  </AnimateInContext.Provider>
);

export const useAnimateInContext = () => useContext(AnimateInContext);
