import {twMerge} from '@/stylesheets/twMerge';

import type {AnimateInItemProps} from './types';
import {useAnimateInContext} from './AnimateInContext';

/**
 * Animates a specific element when its AnimateIn container is in view.
 * Used in conjunction with `AnimateIn`.
 *
 * @example
 *
 * <AnimateIn effect="fade">
 *   {cards.map((card) => (
 *     <AnimateInItem>
 *       <Card {...card} />
 *     </AnimateInItem>
 *   ))}
 * </AnimateIn>
 */
export default function AnimateInItem({
  children,
  className,
  index = 0,
}: AnimateInItemProps) {
  const {animationClassName, delayClassNames} = useAnimateInContext();
  const delayClassName = delayClassNames?.[index] ?? '';

  return (
    <div className={twMerge(delayClassName, className, animationClassName)}>
      {children}
    </div>
  );
}
