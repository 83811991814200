import {useCallback, useState} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import useReducedMotion from '@/hooks/useReducedMotion';

import type {AnimationClassName, UseAnimateInProps} from './types';
import {DEFAULTS, ANIMATION_TYPE_PRESETS} from './constants';

export default function useAnimateIn({
  effect = DEFAULTS.effect,
  customAnimation = {static: '', animated: ''},
  intersectionObserverOptions = DEFAULTS.intersectionObserverOptions,
  ref,
}: UseAnimateInProps) {
  const selectedAnimation =
    effect === 'custom' ? customAnimation : ANIMATION_TYPE_PRESETS[effect];
  const prefersReducedMotion = useReducedMotion(false);
  const staticClassName = prefersReducedMotion
    ? selectedAnimation.animated
    : selectedAnimation.static;
  const animatedClassName = twMerge(
    staticClassName,
    selectedAnimation.animated,
  );
  const [animationClassName, setAnimationClassName] =
    useState<AnimationClassName>(staticClassName);

  // If the user prefers reduced motion then the animations should be visible by default.
  if (prefersReducedMotion && animationClassName !== animatedClassName) {
    setAnimationClassName(animatedClassName);
  }

  // Set animated className when the element is in view.
  const handleIntersectCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        const hasAlreadySetAnimatedClass =
          animationClassName === animatedClassName;
        if (prefersReducedMotion || hasAlreadySetAnimatedClass) return;
        if (entry.isIntersecting) {
          setAnimationClassName(animatedClassName);
        }
      });
    },
    [animatedClassName, animationClassName, prefersReducedMotion],
  );

  useIntersectionObserver(
    ref,
    handleIntersectCallback,
    intersectionObserverOptions,
  );

  return {
    animationClassName,
  };
}
