import BGStaticImage, {type BGStaticImageProps} from './BGStaticImage';

type ImageName =
  | 'pixels-hero'
  | 'pixels-top-bottom'
  | 'pixels-bottom'
  | 'default';

interface BackgroundStaticImageProps {
  imageName: ImageName;
  altText?: string;
}

type ImagePropsMap = {
  [key in ImageName]: BGStaticImageProps;
};

// Static Background Image Component
// Advantages of centralizing this is to be able to universally change a static background, ease of use in applying this to a section,
// ability to use responsive images that are more performant across devices and predictability of the types of backgrounds we have through the imageName.
export default function BackgroundStaticImage({
  imageName,
  altText = 'Pixels of different sizes',
}: BackgroundStaticImageProps) {
  const imageProps: ImagePropsMap = {
    'pixels-hero': {
      image: {
        src: 'https://cdn.shopify.com/s/files/1/0869/6345/4250/files/partners-hero.png?v=1717096620',
        alt: altText,
      },
      mobileImage: {
        src: 'https://cdn.shopify.com/s/files/1/0869/6345/4250/files/partners-hero.png?v=1717096620',
        alt: altText,
      },
    },
    'pixels-top-bottom': {
      image: {
        src: 'https://cdn.shopify.com/b/shopify-brochure2-assets/cf57980bc9490ff6f481aa060b541d71.svg',
        alt: altText,
        width: 1260,
        height: 521,
      },
      mobileImage: {
        src: 'https://cdn.shopify.com/b/shopify-brochure2-assets/52791421ec17072121f35cd30903a84c.svg',
        alt: altText,
        width: 414,
        height: 358,
      },
    },
    'pixels-bottom': {
      containerClassName: 'absolute left-0 bottom-0 w-full h-full',
      imageClassName:
        'w-full h-full object-cover object-bottom hidden md:block',
      mobileImageClassName:
        'w-full h-full object-cover object-bottom block md:hidden',
      image: {
        src: 'https://cdn.shopify.com/b/shopify-brochure2-assets/49a395e075447d2933fa0d96ad97a9e9.svg',
        alt: altText,
        width: 1440,
        height: 464,
      },
      mobileImage: {
        src: 'https://cdn.shopify.com/b/shopify-brochure2-assets/27972e1e468407fe9abd091be04e70c4.svg',
        alt: altText,
        width: 414,
        height: 399,
      },
    },
    default: {
      image: {
        src: 'https://cdn.shopify.com/s/files/1/0869/6345/4250/files/partners-hero.png?v=1717096620',
        alt: altText,
      },
      mobileImage: {
        src: 'https://cdn.shopify.com/s/files/1/0869/6345/4250/files/partners-hero.png?v=1717096620',
        alt: altText,
      },
    },
  };

  const props = imageProps[imageName] || imageProps['default'];

  return <BGStaticImage {...props} />;
}
