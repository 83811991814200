import {useRef} from 'react';

import useAnimateIn from './useAnimateIn';
import type {AnimateInProps} from './types';
import {AnimateInProvider} from './AnimateInContext';
import {DEFAULTS} from './constants';

/**
 * Applies an animation effect to its children when the component enters the viewport.
 * Used in conjunction with `AnimateInItem`.
 *
 * @example
 *
 * <AnimateIn effect="fade">
 *   {cards.map((card) => (
 *     <AnimateInItem>
 *       <Card {...card} />
 *     </AnimateInItem>
 *   ))}
 * </AnimateIn>
 */
export default function AnimateIn({
  effect = DEFAULTS.effect,
  children,
  className,
  customAnimation,
  delaySequence = DEFAULTS.delaySequence,
  intersectionObserverOptions = DEFAULTS.intersectionObserverOptions,
  stagger = DEFAULTS.stagger,
}: AnimateInProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const {animationClassName} = useAnimateIn({
    effect,
    customAnimation,
    intersectionObserverOptions,
    ref: containerRef,
  });
  const delayClassNames = stagger ? delaySequence : [];

  return (
    <div ref={containerRef} className={className}>
      <AnimateInProvider value={{animationClassName, delayClassNames}}>
        {children}
      </AnimateInProvider>
    </div>
  );
}
